<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit External Resource</p>
        <p v-else class="title">Add External Resource</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Type*">
                  <b-select
                    v-model="formData.type"
                    required
                    placeholder="Select a type"
                  >
                    <option value="JSTOR">JSTOR</option>
                    <option value="TF">Taylor & Francis</option>
                    <option value="HEC">HEC</option>
                    <option value="FA">Foreign Affairs</option>
                    <option value="FP">Foreign Policy</option>
                    <option value="OTHERS">Others</option>
                  </b-select>
                </b-field>
                <b-field label="Title*">
                  <b-input v-model="formData.title" required></b-input>
                </b-field>
                <b-field label="Subject*">
                  <b-input v-model="formData.subject" required></b-input>
                </b-field>

                <b-field
                  label="Keyword(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.keywords" required></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Description*">
                  <b-input
                    type="textarea"
                    v-model="formData.description"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Source URL*">
                  <b-input v-model="formData.sourceURL" required></b-input>
                </b-field>
                <b-field
                  label="Publication Year*"
                  class="mb-4"
                  message="Enter year in the format YYYY e.g. 2021"
                >
                  <b-numberinput
                    v-model="formData.publicationYear"
                    :min="1000"
                    :max="9999"
                    :controls="false"
                    expanded
                    required
                  ></b-numberinput>
                </b-field>
                <b-field
                  label="Author(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.authors" required></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-4 is-narrow">
          <div
            v-if="formData.attachmentURL || formData.coverPhotoURL"
            class="box"
          >
            <p class="pb-3 bold has-text-centered">
              <i class="mdi mdi-file-outline pr-1"></i>
              Current Uploads
            </p>
            <hr class="my-1" />
            <div v-if="formData.coverPhotoURL" class="mt-2">
              <div class="bold">Cover Photo</div>
              <div class="columns mt-1">
                <div class="column is-narrow pt-1">
                  <img :src="formData.coverPhotoURL" style="height: 100px" />
                </div>
              </div>
            </div>
            <div class="mt-1">
              <a v-if="formData.attachmentURL" :href="formData.attachmentURL">
                <b-button type="is-info" icon-left="download">
                  View PDF file
                </b-button>
              </a>
            </div>
          </div>

          <div class="box">
            <b-field label="Cover Photo">
              <section>
                <b-field>
                  <b-upload v-model="coverPhotoFile" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in coverPhotoFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
          <div class="box">
            <b-field label="Attach File">
              <section>
                <b-field>
                  <b-upload v-model="attachmentFile" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in attachmentFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,
      formData: {
        type: "JSTOR",
        title: undefined,
        subject: undefined,
        description: undefined,
        keywords: undefined,
        authors: undefined,
        publicationYear: undefined,
        sourceURL: undefined,
        attachmentURL: undefined,
        coverPhotoURL: undefined,
        coverPhotoFile: undefined,
        attachmentFile: undefined
      },
      coverPhotoFile: [],
      attachmentFile: []
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }
    }
  },
  computed: {},
  methods: {
    deleteDropFile(index) {
      this.coverPhotoFile.splice(index, 1);
    },
    deleteattachmentFile(index) {
      this.attachmentFile.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "ExternalResource" });
    },
    deleteResource(type) {
      if (type === "cover") {
        this.formData.coverPhotoURL = undefined;
      }
      if (type === "attachment") {
        this.formData.attachmentURL = undefined;
      }
    },
    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);

      if (this.editMode) {
        const uploadRequest = new FormData();
        uploadRequest.append("attachmentFile", this.attachmentFile[0]);
        uploadRequest.append("coverPhoto", this.coverPhotoFile[0]);
        //this.formData.attachmentURL = undefined;

        // copying rest of the request in formdata
        for (var key1 in this.formData) {
          if (
            key1 != "attachmentFile" &&
            key1 != "coverPhoto" &&
            request[key1] != undefined
          )
            uploadRequest.append(key1, request[key1]);
        }
        if (
          await this.$store.dispatch("editExternalResource", {
            id: this.$route.params.itemDetail._id,
            request: uploadRequest
          })
        ) {
          this.$router.push({ name: "ExternalResource" });
        }
      } else {
        const uploadRequest = new FormData();
        uploadRequest.append("attachmentFile", this.attachmentFile[0]);
        uploadRequest.append("coverPhoto", this.coverPhotoFile[0]);

        // copying rest of the request in formdata
        for (var key2 in this.formData) {
          if (
            key2 != "attachmentFile" &&
            key2 != "coverPhoto" &&
            request[key2] != undefined
          )
            uploadRequest.append(key2, request[key2]);
        }

        if (await this.$store.dispatch("addExternalResource", uploadRequest))
          this.$router.push({ name: "ExternalResource" });
      }

      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
